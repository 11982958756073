import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Table, TableBody, TableCell, CircularProgress, TableContainer, TableHead, TableRow, Typography, Paper, Button, makeStyles } from '@material-ui/core';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
    tableRow: {
        cursor: "pointer",
        padding: "0px",
    },
    hiddenReport: {
        position: 'absolute',
        top: '-9999px',
        left: '-9999px',
        visibility: 'hidden',
    },
}));

export default function CompletedSurveysTable({ data, role }) {
    const classes = useStyles();
    const [downloadStatus, setDownloadStatus] = useState({});

    // Filter data where memberSurveyList has completeness === 100
    const filteredData = data.filter((report) =>
        report.memberSurveyList.some((survey) => survey.completeness === 100)
    );
    
    // Logging completeness score, series name, user name, and survey name
    filteredData.forEach((report) => {
        const seriesName = report.collectionTemplate.name;
        const userName = report.userName; // Assuming userName is available in the report object
        report.memberSurveyList.forEach((survey) => {
            if (survey.completeness === 100) {
                const surveyName = survey.surveyTemplate.name; // Access survey name from surveyTemplate
                console.log(`Series: ${seriesName}, User Name: ${userName}, Survey Name: ${surveyName}, Completeness: ${survey.completeness}`);
            }
        });
    });
    
    
    const formatDate = (dateString) => dateString.substring(0, 10);

    const nameToLink = {
        "Community Connections": "/history/ccReport/",
        "Social Health Screener - NEW": "/history/screenerReport/",
        "Quality of Life - Short": "/history/qoflReport/",
        "Quality Life Series": "/QLReport/",
    };

    const handleDownloadPDF = async (surveyId, reportContent) => {
        setDownloadStatus((prevStatus) => ({ ...prevStatus, [surveyId]: true }));
        
        const hiddenElement = document.createElement('div');
        hiddenElement.id = `report-${surveyId}`;
        hiddenElement.innerHTML = reportContent;
        document.body.appendChild(hiddenElement);

        const reportElement = document.getElementById(`report-${surveyId}`);

        if (!reportElement) {
            console.error(`Report element with id "report-${surveyId}" not found.`);
            setDownloadStatus((prevStatus) => ({ ...prevStatus, [surveyId]: false }));
            return;
        }

        const canvas = await html2canvas(reportElement);

        const opt = {
            margin: 5,
            filename: `${surveyId}_report.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a2', orientation: 'portrait' }
        };

        await html2pdf().set(opt).from(reportElement).save();
        document.body.removeChild(hiddenElement);
        setDownloadStatus((prevStatus) => ({ ...prevStatus, [surveyId]: false }));
    };

    const renderReportContent = (row) => (
        `<h1>${row.collectionTemplate.name}</h1>
        <p>Date Generated: ${formatDate(row.updatedAt)}</p>
        <p>Additional content can be added here...</p>`
    );

    return (
        <div className={classes.root}>
            <Box mx={1} my={1}>
                <TableContainer component={Paper} elevation={0} style={{ border: "1px solid #e0e0e0" }}>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.tableRow} style={{ cursor: 'default', backgroundColor: '#f0f0f0' }}>
                                <TableCell>Series</TableCell>
                                <TableCell>Date Generated</TableCell>
                                <TableCell>Download</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData.length > 0 ? (
                                filteredData.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Typography component={Link} to={nameToLink[row.collectionTemplate.name] + row._id}>
                                                {row.collectionTemplate.name.charAt(0).toUpperCase() + row.collectionTemplate.name.slice(1)} {role !== 'Patient' ? '- Self Care' : ''}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{formatDate(row.updatedAt)}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleDownloadPDF(row._id, renderReportContent(row))}
                                                style={{ width: "150px", height: "40px" }}
                                            >
                                                {downloadStatus[row._id] ? <CircularProgress size={24} color="white" /> : "Download"}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={3} align="center">
                                        <Typography>No data available</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <div className={classes.hiddenReport}></div>
        </div>
    );
}

CompletedSurveysTable.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.string.isRequired,
            collectionTemplate: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }).isRequired,
            updatedAt: PropTypes.string.isRequired,
            memberSurveyList: PropTypes.arrayOf(
                PropTypes.shape({
                    completeness: PropTypes.number.isRequired,
                })
            ).isRequired,
        })
    ),
    role: PropTypes.string.isRequired,
};

CompletedSurveysTable.defaultProps = {
    data: [],
};
