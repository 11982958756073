// ================================================
// Code associated with 
// ================================================
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';                     //Development Package to validate prop types [Type Checking] passed down
import get from '../../../../helpers/common/get';
// ==================== Modules =====================

// ==================== Components ==================

// ==================== Helpers =====================
import AlertType from '../../../../helpers/models/AlertType';
import post from '../../../../helpers/common/post';
// ==================== MUI =========================
import { makeStyles } from '@material-ui/core/styles';  // withStyles can be used for classes and functional componenents but makeStyle is designed for new React with hooks
import AssignmentIcon from '@material-ui/icons/Assignment';

import Grid from '@material-ui/core/Grid';  // Normal Markup with MUI is layout -> Container -> Grid -> Paper etc...
import Box from '@material-ui/core/Box';    // Padding and margins
import Card from '@material-ui/core/Card';  //Like the paper module, a visual sheet to place things
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Typography from '@material-ui/core/Typography';  //h1, p replacement Tag

// ==================== MUI Icons ====================
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import RefreshIcon from '@material-ui/icons/Refresh';

import { Modal, Checkbox, OutlinedInput, CircularProgress } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
// ==================== MUI Styles ===================

const useStyles = makeStyles((theme) =>    //Notice the hook useStyles
({
    root: {
        flexGrow: 1,     // CSS determined this way, flexbox properties
        height: '100%'
    },
    rootGrid: {
        height: '100%'
    }
}));

const stylew = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60vw', // Increased width
    height: '50vh',
    backgroundColor: 'white',
    boxShadow: 24,
    padding: 4,
    padding: '20px',
    overflow: 'auto',
};

const styled = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60vw', // Increased width
    height: '40vh',
    backgroundColor: 'white',
    boxShadow: 24,
    padding: 4,
    padding: '20px',
    overflow: 'auto',
};
// ================= Static Variables ================

const selectFilterOptionsSurvey = [{ key: "SurveyName", value: "surveyName", display: "Name" },
{ key: "SurveyId", value: "surveyId", display: "ID" }];

const selectFilterOptionsMemberSurvey = [{ key: "SurveyName", value: "surveyName", display: "Name" }, { key: "MemberSurveyId", value: "memberSurveyId", display: "ID" },
{ key: "Completeness", value: "completeness", display: "Completeness" },
{ key: "MemberCollectionId", value: "memberCollectionId", display: "Member Series ID" },
{ key: "MemberName", value: "memberName", display: "Member Name" },
{ key: "MemberId", value: "memberId", display: "Member ID" }];

// Add other clients here

// const workbooks = [
//     { id: '1', name: 'Workbook 1' },
//     { id: '2', name: 'Workbook 2' },
//     // Add other workbooks here
// ];
// ================= Static Functions ================


// ======================== React Modern | Functional Component ========================

const WorkbookControlPanel = (props) => { // Notice the arrow function... regular function()  works too

    // Variables ===

    // Style variable declaration
    const classes = useStyles();
    console.log(props.appState._id,'ttttt')
    

    // Declaration of Stateful Variables ===
    const { appState, setParentAlert,
        isDense, setIsDense,
        isTemplates, setIsTemplates,
        dataList, getParentData,
        setSearchFilteredDataList,
        setCreateChapterTemplateDialog,
        setCreateChapterUserDialog } = props;

    const [selectSearchFilterOption, setSelectSearchFilterOption] = useState(selectFilterOptionsMemberSurvey[0].value);
    const [openSetDefaultWorkbookModal, setOpenSetDefaultWorkbookModal] = useState(false);

    const [searchFilter, setSearchFilter] = useState("");
    const [alert, setAlert] = useState(new AlertType());
    const [userList, setUserList] = useState(null);

    // Functions ===
    const [openAssignModal, setOpenAssignModal] = useState(false);
    const [selectedClients, setSelectedClients] = useState([]);
    const [selectedWorkbooks, setSelectedWorkbooks] = useState([]);
    const [workbooks, setworkbooks] = useState([]);

    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleAssignOpen = () => setOpenAssignModal(true);
    const handleAssignClose = () => setOpenAssignModal(false);
    const handleSetDefaultWorkbookOpen = () => setOpenSetDefaultWorkbookModal(true);
    const handleSetDefaultWorkbookClose = () => setOpenSetDefaultWorkbookModal(false);

    // const handleAssignWorkbooks = async () => {
    //     setLoading(true);
    //     try {
    //         // Add your assignment logic here
    //         console.log("Workbooks assigned successfully");
    //         setSuccessMessage("Workbooks have been successfully assigned.");
    //     } catch (error) {
    //         console.error("Error assigning workbooks:", error);
    //     } finally {
    //         setLoading(false);
    //         handleAssignClose();
    //     }
    // };
    const handleAssignWorkbooks = async () => {
        setLoading(true);
        try {
            console.log('selectedWorkbooks:', selectedWorkbooks);
            console.log('selectedClients:', selectedClients);
    
            // Map the selected workbooks and clients to IDs
            const workbookIds = selectedWorkbooks.map(workbookName =>
                workbooks.find(workbook => workbook.name === workbookName)?._id
            );
    
            const userIds = selectedClients.map(clientName =>
                userList.find(client => client.name === clientName)?._id
            );
    
            if (workbookIds.length === 0 || userIds.length === 0) {
                setLoading(false);
                console.error("Please select at least one workbook and one client.");
                return;
            }
    
            // Prepare the payload
            const payload = {
                cordinator_id:props.appState._id,
                workbookId: workbookIds,
                userIds: userIds,
            };
    
            console.log("Payload for assigning workbooks:", payload);
    
            // API call using a post helper
            post("workbooks/assign-collections", appState.token, payload, (err, res) => {
                if (err) {
                    console.error("Error assigning workbooks:", err);
                    if (err.response) {
                        console.error("Server response:", err.response.data);
                    }
                    setAlert(new AlertType("Failed to assign workbooks. Please try again.", "error"));
                } else if (res.status === 200) {
                    console.log("Workbooks assigned successfully:", res.data);
                    setAlert(new AlertType("Workbooks have been successfully assigned.", "success"));
                } else {
                    console.warn("Unexpected response status:", res.status);
                    setAlert(new AlertType("Unexpected error occurred. Please try again.", "error"));
                }
            });
        } catch (error) {
            console.error("Unexpected error assigning workbooks:", error);
            setAlert(new AlertType("An error occurred while assigning workbooks.", "error"));
        } finally {
            setLoading(false);
            handleAssignClose();
            handleSetDefaultWorkbookClose();
        }
    };
    

    const pullHandler = useCallback(() => {
        try {
            getParentData();
            setParentAlert(new AlertType('Refreshed initiated...', "info"));
        }
        catch {

        }

    }, [getParentData, setParentAlert]);

    const createTemplateHandler = useCallback(() => {
        setCreateChapterTemplateDialog(true);
    }, [setCreateChapterTemplateDialog]);

    const createUserHandler = useCallback(() => {
        setCreateChapterUserDialog(true);
    }, [setCreateChapterUserDialog]);

    const compactHandler = useCallback((event) => {
        setIsDense(event.target.checked);
    }, [setIsDense]);

    const chapterTemplatesButtonHandler = useCallback(() => {
        setIsTemplates(true);
        setSelectSearchFilterOption(selectFilterOptionsMemberSurvey[0].value);
    }, [setIsTemplates]);

    const userChaptersButtonHandler = useCallback(() => {
        setIsTemplates(false);
        setSelectSearchFilterOption(selectFilterOptionsMemberSurvey[0].value);
    }, [setIsTemplates]);

    const selectSearchHandler = useCallback((event) => {
        setSearchFilter("");
        setSelectSearchFilterOption(event.target.value);

    }, [setSelectSearchFilterOption]);
    const getUserWorkbooks = useCallback(() => {
        console.log("Fetching user workbooks..."); // Log to confirm function is called
        get("workbooks/user", appState.token, (err, res) => {
            if (err) {
                console.error("Error fetching user workbooks:", err);
                if (err.response) {
                    console.error("Server response:", err.response.data);  // Logs the server's error response
                }
                setAlert(new AlertType('Unable to retrieve workbooks. Please refresh and try again.', "error"));
            } else if (res.status === 200) {
                console.log("Workbooks fetched successfully:", res.data);
                // setIsLoading(false);

                setworkbooks(res.data);


            } else {
                console.warn("Unexpected response status:", res.status);
                setAlert(new AlertType('Unable to retrieve workbooks. Please refresh and try again.', "error"));
            }
        });
    }, [appState.token]);
    const populateUsers = useCallback((data) => {
        let tempArray = new Array();

        if (data && Array.isArray(data)) {
            data.forEach(item => {

                tempArray.push(
                    {
                        _id: item._id,
                        name: item.info.name,
                        role: item.role,
                        createdAt: item.createdAt
                    });
            });
        }

        setUserList([...tempArray]);
    }, [appState, setUserList]);
    const getUsers = useCallback(() => {

        get("users/", appState.token, (err, res) => {
            if (err) {
                //Bad callback call
                //setParentAlert(new AlertType(err.message, "error"));
                setParentAlert(new AlertType('Unable to retrieve Users. Please refresh and try again.', "error"));
            }
            else {
                if (res.status === 200) {
                    if (appState.role == 'Admin' && appState.role == 'cordinator') {
                        populateUsers(res.data.response.users)
                        console.log("Work fetched successfully:", res.data);

                    }
                    else {
                        var tempusers = [];

                        res.data.response.users.forEach(k => {
                            if (k.facid == appState.facilityId)
                                tempusers.push(k);
                        });
                        // console.log("**********************************")
                        // console.log(tempusers)
                        populateUsers(tempusers); //Edit by P, filter users by facility ID
                        //populateUsers(res.data.response.users);
                    }
                }
                else {
                    //Bad HTTP Response
                    setParentAlert(new AlertType('Unable to retrieve Users. Please refresh and try again.', "error"));
                }

            }

        });
    }, [appState, populateUsers, setParentAlert]);
    const searchHandler = useCallback((event) => {
        let tempFilter = event.target.value.toUpperCase();
        let tempArray = [];
        dataList.forEach(item => {
            switch (selectSearchFilterOption) {
                case "surveyName":

                    if (item.name.toUpperCase().indexOf(tempFilter) > -1) {
                        tempArray.push(item);
                    }

                    break;

                case "surveyId":

                    if (item._id.toUpperCase().indexOf(tempFilter) > -1) {
                        tempArray.push(item);
                    }

                    break;

                case "memberSurveyId":

                    if (item._id.toUpperCase().indexOf(tempFilter) > -1) {
                        tempArray.push(item);
                    }

                    break;

                case "completeness":

                    if (item.completeness.toUpperCase().indexOf(tempFilter) > -1) {
                        tempArray.push(item);
                    }

                    break;

                case "memberCollectionId":

                    if (item.memberCollection._id.toUpperCase().indexOf(tempFilter) > -1) {
                        tempArray.push(item);
                    }

                    break;

                case "memberName":

                    if (item.member.info.name.toUpperCase().indexOf(tempFilter) > -1) {
                        tempArray.push(item);
                    }

                    break;

                case "memberId":

                    if (item.member._id.toUpperCase().indexOf(tempFilter) > -1) {
                        tempArray.push(item);
                    }

                    break;

                default:
            }

        });

        setSearchFilter(event.target.value);
        setSearchFilteredDataList(tempArray);
    }, [dataList, setSearchFilteredDataList, selectSearchFilterOption]);


    // Hooks ===

    // First Render only because of the [ ] empty array tracking with the useEffect
    useEffect(() => {
        setSearchFilter("");
        getUserWorkbooks();
        getUsers();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataList, getUsers]);

    useEffect(() => {
        if (isTemplates) {
            setSelectSearchFilterOption(selectFilterOptionsSurvey[0].value)
        }
        else {
            setSelectSearchFilterOption(selectFilterOptionsMemberSurvey[0].value)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTemplates]);

    useEffect(() => {
        if (searchFilter === "") {
            setSearchFilteredDataList(dataList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSelectSearchFilterOption, searchFilter]);

    // Render Section ===

    return (
        <>
            <Box mx={2} my={1} boxShadow={0}>
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={1}
                >
                    <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="stretch" spacing={1}>
                        {/* <Grid item>
                            <Typography variant="h6" component="h6">
                                Manage
                            </Typography>
                            <Divider />
                        </Grid> */}
                        <Grid item xs>
                            <Box mx={3} my={1} boxShadow={0}>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                                    <Grid item>
                                        <Tooltip
                                            placement="bottom"
                                            title="Refresh Online Modules"
                                        >
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="primary"
                                                startIcon={<RefreshIcon />}
                                                onClick={() => { pullHandler(); }}
                                            >
                                                Refresh
                                            </Button>
                                        </Tooltip>
                                    </Grid>

                                    {isTemplates ? (
                                        <>
                                            <Grid item>
                                                <Tooltip
                                                    placement="bottom"
                                                    title="Create Workbook"
                                                >
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={!isTemplates}
                                                        startIcon={<AddBoxOutlinedIcon />}
                                                        onClick={() => { createTemplateHandler(); }}
                                                    >
                                                        Create Workbook
                                                    </Button>

                                                </Tooltip>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip
                                                    placement="bottom"
                                                    title="Assign User"
                                                >
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        color="secondary"
                                                        startIcon={<AssignmentIcon />}
                                                        //onClick={() => { createworkbookHandler(); }}
                                                        onClick={handleAssignOpen}

                                                    >
                                                        Assign Workbook
                                                    </Button>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip placement="bottom" title="Assign User">
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        color="secondary"
                                                        startIcon={<AssignmentIcon />}
                                                        onClick={handleSetDefaultWorkbookOpen}
                                                    >
                                                        Set Default Workbook
                                                    </Button>
                                                </Tooltip>
                                            </Grid>

                                            <Modal
                                                aria-labelledby="set-default-workbook-modal"
                                                aria-describedby="set-default-workbook-description"
                                                open={openSetDefaultWorkbookModal}
                                                onClose={handleSetDefaultWorkbookClose}
                                                closeAfterTransition
                                                BackdropComponent={Backdrop}
                                                BackdropProps={{ timeout: 500 }}
                                            >
                                                <Fade in={openSetDefaultWorkbookModal}>
                                                    <Box style={{ ...styled, padding: '20px' }}>
                                                        <Typography id="set-default-workbook-title" variant="h6">Set Default Workbook</Typography>
                                                        <Typography id="transition-modal-description" variant="body1" gutterBottom>
                                                            Please select a Workbook
                                                        </Typography>

                                                        <FormControl fullWidth variant="outlined" style={{ marginTop: '20px' }}>
                                                            <InputLabel>Select Workbooks</InputLabel>
                                                            <Select
                                                                multiple
                                                                value={selectedWorkbooks}
                                                                onChange={(e) => setSelectedWorkbooks(e.target.value)}
                                                                input={<OutlinedInput label="Select Workbooks" />}
                                                                renderValue={(selected) => selected.join(', ')}
                                                            >
                                                                {workbooks.map(workbook => (
                                                                    <MenuItem key={workbook._id} value={workbook.name}>
                                                                        <Checkbox checked={selectedWorkbooks.includes(workbook.name)} />
                                                                        {workbook.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>

                                                        {loading ? <CircularProgress /> : (
                                                            <Button onClick={handleAssignWorkbooks} color="primary" variant="contained" style={{ marginTop: '20px' }}>
                                                                Assign
                                                            </Button>
                                                        )}
                                                        <Button onClick={handleSetDefaultWorkbookClose} color="secondary" variant="contained" style={{ marginTop: '20px', marginLeft: '10px' }}>
                                                            Cancel
                                                        </Button>
                                                    </Box>
                                                </Fade>
                                            </Modal>

                                            <Modal
                                                aria-labelledby="assign-workbooks-modal"
                                                aria-describedby="assign-workbooks-description"
                                                open={openAssignModal}
                                                onClose={handleAssignClose}
                                                closeAfterTransition
                                                BackdropComponent={Backdrop}
                                                BackdropProps={{ timeout: 500 }}
                                            >
                                                <Fade in={openAssignModal}>
                                                    <Box style={{ ...stylew, padding: '20px' }}>
                                                        <Typography id="assign-workbooks-title" variant="h6">Assign Workbooks</Typography>
                                                        <Typography id="transition-modal-description" variant="body1" gutterBottom>
                                                            Please select Client and Workbook
                                                        </Typography>
                                                        <FormControl fullWidth variant="outlined" style={{ marginTop: '10px' }}>

                                                            <InputLabel>Select Clients</InputLabel>
                                                            <Select
                                                                multiple
                                                                value={selectedClients}
                                                                onChange={(e) => setSelectedClients(e.target.value)}
                                                                input={<OutlinedInput label="Select Clients" />}
                                                                renderValue={(selected) => selected.join(', ')}
                                                            >
                                                                {/* Map through clients list */}
                                                                {userList?.map(client => (
                                                                    <MenuItem key={client._id} value={client.name}>
                                                                        <Checkbox checked={selectedClients.includes(client.name)} />
                                                                        {client.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>

                                                        <FormControl fullWidth variant="outlined" style={{ marginTop: '20px' }}>
                                                            <InputLabel>Select Workbooks</InputLabel>
                                                            <Select
                                                                multiple
                                                                value={selectedWorkbooks}
                                                                onChange={(e) => setSelectedWorkbooks(e.target.value)}
                                                                input={<OutlinedInput label="Select Workbooks" />}
                                                                renderValue={(selected) => selected.join(', ')}
                                                            >
                                                                {/* Map through workbooks list */}
                                                                {workbooks.map(workbook => (
                                                                    <MenuItem key={workbook._id} value={workbook.name}>
                                                                        <Checkbox checked={selectedWorkbooks.includes(workbook.name)} />
                                                                        {workbook.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>

                                                        {loading ? <CircularProgress /> : (
                                                            <Button onClick={handleAssignWorkbooks} color="primary" variant="contained" style={{ marginTop: '20px' }}>
                                                                Assign
                                                            </Button>
                                                        )}
                                                        <Button onClick={handleAssignClose} color="secondary" variant="contained" style={{ marginTop: '20px', marginLeft: '10px' }}>
                                                            Cancel
                                                        </Button>
                                                    </Box>
                                                </Fade>
                                            </Modal>

                                        </>
                                    ) : (
                                        <>
                                            {/* <Grid item>
                                                <Tooltip
                                                    placement="bottom"
                                                    title="Start Member Module"
                                                >
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={isTemplates}
                                                        startIcon={<AddBoxOutlinedIcon />}
                                                        onClick={() => { createUserHandler(); }}
                                                    >
                                                        Start Member Module
                                                    </Button>
                                                </Tooltip>
                                            </Grid> */}
                                        </>
                                    )}

                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Tooltip
                                placement="left"
                                title="Use this page to manage your Modules. Any information that a patient has answered in a Module along with its versions exist in the Module Tab. Otherwise, created Module Templates exists in its own tab."
                            >
                                <IconButton>
                                    <HelpOutlineIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
                        <Box mx={1} my={1} boxShadow={0}>
                            <Card>
                                <Box mx={1} my={1} boxShadow={0}>
                                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3}>
                                        {/* <Grid item>
                                                <Typography color="textPrimary" variant="subtitle2">
                                                    Table Options
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    control={<Switch checked={isDense} onChange={ (event) => { compactHandler(event); }} />}
                                                    label="Compact"
                                                    labelPlacement="end"
                                                />
                                            </Grid> */}
                                        <Grid item>
                                            <FormControl id="search-filter-select-label" variant="filled" size="small" style={{ minWidth: 140 }}>
                                                <InputLabel>
                                                    Search Filter
                                                </InputLabel>
                                                <Select
                                                    autoWidth={true}
                                                    labelId="search-filter-select-label"
                                                    value={selectSearchFilterOption}
                                                    onChange={(event) => { selectSearchHandler(event); }}
                                                >
                                                    {isTemplates ? (
                                                        selectFilterOptionsSurvey.map(item => {
                                                            return (
                                                                <MenuItem key={item.key} value={item.value}>
                                                                    <em>{item.display}</em>
                                                                </MenuItem>
                                                            )
                                                        })
                                                    ) : (
                                                        selectFilterOptionsMemberSurvey.map(item => {
                                                            return (
                                                                <MenuItem key={item.key} value={item.value}>
                                                                    <em>{item.display}</em>
                                                                </MenuItem>
                                                            )
                                                        })
                                                    )}

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs>
                                            <TextField label="Search"
                                                type="search"
                                                size="small"
                                                variant="filled"
                                                fullWidth
                                                value={searchFilter}
                                                onChange={(event) => { searchHandler(event); }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Card>
                        </Box>
                    </Grid>
                    {/* <Grid item xs={12} container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                        <Grid item>
                            <Typography variant="h6" component="h6">
                                Table
                            </Typography>
                            <Divider />
                        </Grid>
                    </Grid> */}
                </Grid>
            </Box>
        </>
    );
}

// ======================== Component PropType Check ========================
WorkbookControlPanel.propTypes =
{
    // You can specify the props types in object style with ___.PropTypes.string.isRequired etc...
    setParentAlert: PropTypes.func.isRequired,
    isDense: PropTypes.bool.isRequired,
    setIsDense: PropTypes.func.isRequired,
    isTemplates: PropTypes.bool.isRequired,
    setIsTemplates: PropTypes.func.isRequired,
    dataList: PropTypes.arrayOf(PropTypes.object).isRequired,
    getParentData: PropTypes.func.isRequired,
    setSearchFilteredDataList: PropTypes.func.isRequired,
    setCreateChapterTemplateDialog: PropTypes.func.isRequired,
    setCreateChapterUserDialog: PropTypes.func.isRequired

}

WorkbookControlPanel.defaultProps =
{
    setParentAlert: () => { },
    setIsDense: () => { },
    setIsTemplates: () => { },
    dataList: {},
    getParentData: () => { },
    setSearchFilteredDataList: () => { },
    setCreateChapterTemplateDialog: () => { },
    setCreateChapterUserDialog: () => { }
}

export default WorkbookControlPanel;  // You can even shorthand this line by adding this at the function [Component] declaration stage