// ================================================
// Code associated with Template Createworkbook page.
// Displays all existing Templates created and allows
// user to delete, edit and preview the Templates
// survey questions.
// ================================================
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';                     //Development Package to validate prop types [Type Checking] passed down

// ==================== Components ====================
import { Link } from "react-router-dom";
import AlertType from '../../../helpers/models/AlertType';
import get from '../../../helpers/common/get';
// ==================== MUI ====================
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { CircularProgress, Divider, LinearProgress } from "@material-ui/core";
import CreateChapterTemplateDialog from '../Booklets/Dialog/CreateWorkbookDialog';
import CreateChapterUserDialog from '../Booklets/Dialog/CreateChapterUserDialog';
import ExportChapterDialog from '../Booklets/Dialog/ExportChapterDialog';
import DeleteChapterDialog from '../Booklets/Dialog/DeleteChapterDialog';
import AlertMessage from '../../../components/AlertMessage';
import ChapterTable from '../Booklets/ChapterTable/ChapterTable';
import ManagementControlPanel from '../Booklets/Management/ManagementControlPanel';
// ==================== Helpers ====================

// ==================== MUI ====================
import { makeStyles } from '@material-ui/core/styles';  // withStyles can be used for classes and functional componenents but makeStyle is designed for new React with hook

import WorkbookControlPanel from '../Booklets/Management/WorkbookControlPanel';

// ==================== Styles ====================
const useStyles = makeStyles((theme) =>    //Notice the hook useStyles
({
    root: {
        flexGrow: 1     // CSS determined this way, flexbox properties
    }
}));

// ======================== React Modern | Functional Component ========================

const Createworkbook = (props) => { // Notice the arrow function... regular function()  works too

    // Variables ===

    // Style variable declaration
    const classes = useStyles();

    // Declaration of Stateful Variables ===
    const { appState, ToggleDrawerClose, CheckAuthenticationValidity } = props;

    // Template or User Chapters boolean
    const [isTemplates, setIsTemplates] = useState(true);

    // IsDense ; is the template table in compact form
    const [isDense, setIsDense] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    // Current dataList variable
    const [dataList, setDataList] = useState(null);
    const [workbooklist, setworkbooklist] = useState(null);
    // Current dataList variable
    const [searchFilteredDataList, setSearchFilteredDataList] = useState(null);
    const [openBoxes, setOpenBoxes] = useState({});

    // Current selected items dataList variable
    const [selectedDataItemsList, setSelectedDataItemsList] = useState(null);

    // Create Chapter Template Dialog Logic variables
    const [createChapterTemplateDialog, setCreateChapterTemplateDialog] = useState(false);
    const [createChapterTemplateDialogExecuting, setCreateChapterTemplateDialogExecuting] = useState(false);
    // const [createCreateWorkbookDialog, setCreateWorkbookDialog] = useState(false);
    // const [CreateWorkbookDialogExecuting, setCreateWorkbookDialogExecuting] = useState(false);
    // Export Chapter / Template Dialog Logic variables
    const [exportChapterDialog, setExportChapterDialog] = useState(false);
    const [exportChapterDialogExecuting, setExportChapterDialogExecuting] = useState(false);

    // Create Chapter Template Dialog Logic variables
    const [deleteChapterDialog, setDeleteChapterDialog] = useState(false);
    const [deleteChapterDialogExecuting, setDeleteChapterDialogExecuting] = useState(false);

    // Create User Chapter Dialog Logic variables
    const [createChapterUserDialog, setCreateChapterUserDialog] = useState(false);
    const [createChapterUserDialogExecuting, setCreateChapterUserDialogExecuting] = useState(false);


    // Alert variable
    const [alert, setAlert] = useState(new AlertType());

    // Functions ===

    const populateList = useCallback((data) => {
        let tempArray = [];

        if (isTemplates) {
            for (let index = 0; index < data.length; ++index) {
                tempArray.push(
                    {
                        _id: data[index]._id,
                        name: data[index].name,
                        surveyJSON: data[index].surveyJSON,
                        createdAt: data[index].createdAt,
                        createdBy: data[index].createdBy,
                        updatedAt: data[index].updatedAt,
                        modifiedBy: data[index].modifiedBy
                    }
                );
            }
        }
        else {
            for (let index = 0; index < data.length; ++index) {
                tempArray.push(
                    {
                        _id: data[index]._id,
                        surveyTemplate: data[index].surveyTemplate,
                        memberCollection: data[index].memberCollection || "",
                        member: data[index].member,
                        responseJSON: data[index].responseJSON,
                        completeness: data[index].completeness,
                        createdAt: data[index].createdAt,
                        createdBy: data[index].createdBy,
                        updatedAt: data[index].updatedAt,
                        modifiedBy: data[index].modifiedBy
                    }
                );
            }
        }

        setDataList([...tempArray]);
        setSearchFilteredDataList([...tempArray]);
        setSelectedDataItemsList([]);

    }, [isTemplates]);
    const getUserWorkbooks = useCallback(() => {
        console.log("Fetching user workbooks..."); // Log to confirm function is called
        get("workbooks/user", appState.token, (err, res) => {
            if (err) {
                console.error("Error fetching user workbooks:", err);
                if (err.response) {
                    console.error("Server response:", err.response.data);  // Logs the server's error response
                }
                setAlert(new AlertType('Unable to retrieve workbooks. Please refresh and try again.', "error"));
            } else if (res.status === 200) {
                console.log("Workbooks fetched successfully:", res.data);
                setIsLoading(false);

                setworkbooklist(res.data);


            } else {
                console.warn("Unexpected response status:", res.status);
                setAlert(new AlertType('Unable to retrieve workbooks. Please refresh and try again.', "error"));
            }
        });
    }, [appState.token]);


    // Retrieve the list of Current user Chapters
    const getUserChapters = useCallback(() => {

        setDataList(null);

        get("membersurveys/", appState.token, (err, res) => {
            if (err) {
                //Bad callback call
                //setAlert(new AlertType(err.message, "error"));
                setAlert(new AlertType('Unable to retrieve User Chapters. Please refresh and try again.', "error"));
            }
            else {
                if (res.status === 200) {
                    populateList(res.data.memberSurveyList);
                }
                else {
                    //Bad HTTP Response
                    setAlert(new AlertType('Unable to retrieve User Chapters. Please refresh and try again.', "error"));
                }
            }

        });
    }, [populateList, appState.token]);

    // Retrieve the list of Current Templates
    const getTemplates = useCallback(() => {
        setDataList(null);

        get("surveys/", appState.token, (err, res) => {
            if (err) {
                //Bad callback call
                //setAlert(new AlertType(err.message, "error"));
                setAlert(new AlertType('Unable to retrieve templates. Please refresh and try again.', "error"));
            }
            else {
                if (res.status === 200) {
                    populateList(res.data.surveyList);
                }
                else {
                    //Bad HTTP Response
                    setAlert(new AlertType('Unable to retrieve templates. Please refresh and try again.', "error"));
                }
            }

        });
    }, [populateList, appState.token]);

    // handles toggle of open/close collection boxes
    const toggleBox = (key) => {
        setOpenBoxes(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };
    const getData = useCallback(() => {
        isTemplates ? getTemplates() : getUserChapters();
    }, [isTemplates, getTemplates, getUserChapters]);

    // Hooks ===

    // Fetch DataList | First Render Only
    useEffect(() => {
        ToggleDrawerClose();
        setTimeout(() => {
            CheckAuthenticationValidity((tokenValid) => {
                if (tokenValid) {
                    getData();
                    getUserWorkbooks();
                }
                else {
                    //Bad HTTP Response
                    setAlert(null);
                }
            });
        }, 200);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUserWorkbooks, getData]);

    // Fetch DataList version | Based on isTemplate or not
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTemplates]);

    useEffect(() => {

        setSearchFilteredDataList(dataList);
        setSelectedDataItemsList([]);

    }, [dataList]);

    // Component Render Section ===
    return (
        alert != null ? (
            // Notice the shorthand React render Fragment <> & </> instead of <div> & </div>, both work the same
            <div className={classes.root}>
                <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    style={{ "height": "100%" }}
                    spacing={1}
                >
                    <Grid item xs={5}>
                        <Box mx={1} my={1}>
                            <Typography variant="h5" color="inherit" align="left" gutterBottom>
                                Manage Workbook
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box mx={1} my={1}>
                            <AlertMessage alert={alert} setParentAlert={setAlert} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mx={1} my={1} boxShadow={3}>
                            <Card raised={true}>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="stretch"
                                    spacing={0}
                                >
                                    {dataList && searchFilteredDataList && selectedDataItemsList ? (
                                        <Grid item xs={12}>
                                            <WorkbookControlPanel
                                                isDense={isDense}
                                                setIsDense={setIsDense}
                                                isTemplates={isTemplates}
                                                setIsTemplates={setIsTemplates}
                                                dataList={dataList}
                                                getParentData={getData}
                                                setSearchFilteredDataList={setSearchFilteredDataList}
                                                setCreateChapterTemplateDialog={setCreateChapterTemplateDialog}
                                                setCreateChapterUserDialog={setCreateChapterUserDialog}
                                                setParentAlert={setAlert}
                                                appState = {appState}
                                            // setCreateWorkbookDialog={setCreateWorkbookDialog}
                                            />
                                            <Grid item xs={12}>
                                                
                                                    <CardContent className="dashboard-card">
                                                        <Box mx={1} my={1} >
                                                            <Grid
                                                                container
                                                                direction="column"
                                                                justifyContent="flex-start"
                                                                alignItems="stretch"
                                                                spacing={1}
                                                            >
                                                                {isLoading ? (
                                                                    <CircularProgress />
                                                                ) : (
                                                                    <Grid item xs={12}>
                                                                        <Grid item xs={12}>
                                                                            {/* Display list of workbooks */}
                                                                            {workbooklist && workbooklist.length > 0 ? (
                                                                                workbooklist.map((workbook, index) => (
                                                                                    <div key={`workbook-${index}`}>
                                                                                        <Box
                                                                                            mt={1.5}
                                                                                            p={1.5}
                                                                                            sx={{ minHeight: '10px' }} // Adjust this value as needed

                                                                                            className="box-container"
                                                                                            onClick={() => toggleBox(`workbook-${index}`)}
                                                                                        >  <h3>
                                                                                                {workbook.name}                                                                                      </h3>

                                                                                        </Box>

                                                                                        {openBoxes[`workbook-${index}`] && (
                                                                                            <Box m={0} p={1.5} className="bottom-container">
                                                                                                <div className="survey-div">
                                                                                                    {workbook.surveys.map((survey, surveyIndex) => (
                                                                                                    console.log(survey._id),
                                                                                                        <Grid item xs={12} key={surveyIndex}>
                                                                                                            <Tooltip placement="bottom" title="Edit Module">
                                                                                                                <Box m={1} pt={1} className="survey-box">
                                                                                                                    <Button
                                                                                                                        className="survey-name"
                                                                                                                        size="small"
                                                                                                                        variant="contained"
                                                                                                                        color="primary"
                                                                                                                        startIcon={<EditIcon />}
                                                                                                                        component={Link}
                                                                                                                       // to={`/administration/booklets/user/view/${survey._id}`}
                                                                                                                    >
                                                                                                                        {survey.name}
                                                                                                                    </Button>

                                                                                                                    {/* Survey Progress indicator */}
                                                                                                                    {/* {survey.completeness === 0 && (
                                                                                                                        <div className="status-div not-started">
                                                                                                                            <span>Not Started</span>
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                    {survey.completeness > 0 &&
                                                                                                                        survey.completeness < 100 && (
                                                                                                                            <div className="status-div in-progress">
                                                                                                                                <span>In Progress</span>
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    {survey.completeness === 100 && (
                                                                                                                        <div className="status-div completed">
                                                                                                                            <span>Completed</span>
                                                                                                                        </div>
                                                                                                                    )} */}
                                                                                                                </Box>
                                                                                                            </Tooltip>
                                                                                                        </Grid>
                                                                                                    ))}
                                                                                                </div>
                                                                                            </Box>
                                                                                        )}
                                                                                    </div>
                                                                                ))
                                                                            ) : (
                                                                                <Typography variant="body1">No workbooks found.</Typography>
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </Box>
                                                    </CardContent>
                                            
                                            </Grid>

                                            {/* <ChapterTable
                                                isTemplates={isTemplates}
                                                isDense={isDense}
                                                searchFilteredDataList={searchFilteredDataList}
                                                selectedDataItemsList={selectedDataItemsList}
                                                setSelectedDataItemsList={setSelectedDataItemsList}
                                                setParentDeleteChapterDialog={setDeleteChapterDialog}
                                                setParentExportChapterDialog={setExportChapterDialog}
                                            /> */}
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12} container direction="row" justifyContent="center" alignItems="stretch" spacing={1}>
                                            <Grid item>
                                                <Box mx={1} my={1} boxShadow={0}>
                                                    <CircularProgress />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                    }
                                </Grid>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
                <CreateChapterTemplateDialog
                    createChapterTemplateDialog={createChapterTemplateDialog}
                    setCreateChapterTemplateDialog={setCreateChapterTemplateDialog}
                    createChapterTemplateDialogExecuting={createChapterTemplateDialogExecuting}
                    setCreateChapterTemplateDialogExecuting={setCreateChapterTemplateDialogExecuting}
                    setParentAlert={setAlert}
                    getParentData={getData}
                    appState={appState}
                />
                <CreateChapterUserDialog
                    createChapterUserDialog={createChapterUserDialog}
                    setCreateChapterUserDialog={setCreateChapterUserDialog}
                    createChapterUserDialogExecuting={createChapterUserDialogExecuting}
                    setCreateChapterUserDialogExecuting={setCreateChapterUserDialogExecuting}
                    setParentAlert={setAlert}
                    getParentData={getData}
                    appState={appState}
                />
                <ExportChapterDialog
                    isTemplates={isTemplates}
                    exportChapterDialog={exportChapterDialog}
                    setExportChapterDialog={setExportChapterDialog}
                    exportChapterDialogExecuting={exportChapterDialogExecuting}
                    setExportChapterDialogExecuting={setExportChapterDialogExecuting}
                    selectedDataItemsList={selectedDataItemsList}
                    setParentAlert={setAlert}
                    getParentData={getData}
                />
                <DeleteChapterDialog
                    deleteChapterDialog={deleteChapterDialog}
                    setDeleteChapterDialog={setDeleteChapterDialog}
                    deleteChapterDialogExecuting={deleteChapterDialogExecuting}
                    setDeleteChapterDialogExecuting={setDeleteChapterDialogExecuting}
                    selectedDataItemsList={selectedDataItemsList}
                    setSelectedDataItemsList={setSelectedDataItemsList}
                    setParentAlert={setAlert}
                    getParentData={getData}
                    isTemplate={isTemplates}
                    appState={appState}
                />
            </div>
        ) : (
            <Typography variant="h6" color="inherit" align="center" gutterBottom>
                Not Authorized. Please refresh and try again.
            </Typography>
        )

    );
}

// ======================== Component PropType Check ========================
Createworkbook.propTypes =
{
    // You can specify the props types in object style with ___.PropTypes.string.isRequired etc...
    appState: PropTypes.object.isRequired,
    ToggleDrawerClose: PropTypes.func.isRequired,
    CheckAuthenticationValidity: PropTypes.func.isRequired
}

Createworkbook.defaultProps = {
    appState: {},
    ToggleDrawerClose: () => { },
    CheckAuthenticationValidity: () => { }
}

export default Createworkbook;  // You can even shorthand this line by adding this at the function [Component] declaration stage