// import React, { useState, useEffect, useCallback, useRef } from "react";
// import PropTypes from 'prop-types';
import CompletedSurveysTable from "../Administration/Users/Components/UserStatusTab/CompletedSurveysTable";

// import Typography from "@material-ui/core/Typography";
// import { makeStyles } from "@material-ui/core/styles";
// import { CircularProgress , Divider, LinearProgress} from "@material-ui/core";
// import get from '../../helpers/common/get';
// import AlertType from "../../helpers/models/AlertType";
// import patch from "../../helpers/common/patch";




// const MainReports= (props) => {
//     // const appState = useRef(AppState.currentState);
//     const { appState} = props;
//     const [userID,setuserID] = useState(null)
//     const [alert, setAlert] = useState(new AlertType());
//     const [memberCollections, setMemberCollections] = useState([]);
//     const [isLoading, setIsLoading] = useState(true);

//     const getMemberCollections = useCallback(() => {
//         if (!userID) {
//             setAlert(new AlertType('User ID is not defined.', "error"));
//             return;
//         }

//         get("membercollections/client/" + userID, appState.token, (err, res) => {
//             if (err) {
//                 setAlert(new AlertType('Unable to retrieve Member Series. Please refresh and try again.', "error"));
//             } else {
//                 if (res.status === 200) {
//                     setMemberCollections(res.data.memberCollections);
//                     setIsLoading(false);
//                 } else {
//                     setAlert(new AlertType('Unable to retrieve Member Series. Please refresh and try again.', "error"));
//                 }
//             }
//         });
//     }, [userID, appState.token]);

//     useEffect(() => {
//         getMemberCollections();
//         const userID = localStorage.getItem('_id');
//         setuserID(userID)

//         getMemberCollections();
//       // stop loading
//     //   setIsLoading(false);
//     },[ getMemberCollections]);

//     return (
//         <>
//             <div>
                
//                 <Typography>Completed Report</Typography>
//                 <Divider />
//                 {isLoading?<CircularProgress style={{position: 'absolute',
//     top: '20%',
//     left: '50%',
//     }}/>:
                
//                 <CompletedSurveysTable
//                     data={memberCollections ? memberCollections : []}
//                     role={appState.role}
//                 />
// }
//             </div>
//         </>
//     );
// };

// MainReports.propTypes = {
//     userID: PropTypes.string.isRequired,
//     appState: PropTypes.object.isRequired,
//     userOriginal: PropTypes.object,
//     setParentAlert: PropTypes.func,
//     getParentInfo: PropTypes.func,
// };

// MainReports.defaultProps = {
//     appState: {},
//     userID: null,
//     userOriginal: {},
//     setParentAlert: () => {},
//     getParentInfo: () => {},
// };

// export default MainReports;


import React, { useState, useEffect, useCallback } from "react";
import { CircularProgress, Typography, Divider } from "@material-ui/core";
import get from '../../helpers/common/get';
import AlertType from "../../helpers/models/AlertType";
import {

    Card,
    CardContent,
    Grid,
    Snackbar,
} from "@material-ui/core";
import { People, AssignmentTurnedIn, HourglassEmpty } from "@material-ui/icons";
const MainReports = (props) => {
    const { appState, ToggleDrawerClose } = props;
    const [patientCollections, setPatientCollections] = useState([]);
    const [alert, setAlert] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const totalUsers = 150;
    const surveysCompleted = 80;
    const surveysInProgress = 50;
    const getPatientCollections = useCallback(async (patientID) => {
        return new Promise((resolve, reject) => {
            get(`membercollections/client/${patientID}`, appState.token, (err, res) => {
                if (err) {
                    reject(new Error('Unable to retrieve Patient Collections. Please refresh and try again.'));
                } else if (res.status === 200) {
                    console.log(res.data,"AAAAAAAAAAAAAIIIIIIIIIIIII")
                    resolve(res.data.memberCollections); 
                    // Check this field name matches API response
                    
                } else {
                    reject(new Error('Bad HTTP Response'));
                }
            });
        });
    }, [appState.token]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (appState.role !== 'Admin' && appState.role !== 'Patient') {
                    try {
                        const patientIds = appState.patients; // Assuming appState.patients is an array of patient IDs
                        const patientCollectionPromises = patientIds.map(id => getPatientCollections(id));
                        const patientCollections = await Promise.all(patientCollectionPromises);
                        setPatientCollections(patientCollections.flat());
                        console.log(patientCollections, 'Fetched Patient Collections');
                    } catch (error) {
                        console.error('Error fetching patient collections:', error);
                        setAlert({ message: error.message, severity: "error" });
                    }
                }
            } catch (error) {
                console.error('Error in fetchData:', error);
                setAlert({ message: 'An error occurred while fetching data. Please try again.', severity: 'error' });
            } finally {
                setIsLoading(false);
            }
        };

        ToggleDrawerClose();
        fetchData();
    }, [appState, getPatientCollections, ToggleDrawerClose]);

    
    return (
        <div> <Typography variant="h5" gutterBottom>Dashboard</Typography>
        <Divider style={{ margin: "10px 0" }} />

        {/* Static Statistics */}
        <Grid container spacing={3} style={{ margin: "20px 0" }}>
            <Grid item xs={12} sm={4}>
                <Card>
                    <CardContent>
                        <People style={{ fontSize: 50, color: "#3f51b5" }} />
                        <Typography variant="h6">{totalUsers}</Typography>
                        <Typography>Total Active Users</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Card>
                    <CardContent>
                        <AssignmentTurnedIn style={{ fontSize: 50, color: "#4caf50" }} />
                        <Typography variant="h6">{surveysCompleted}</Typography>
                        <Typography>Surveys Completed</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Card>
                    <CardContent>
                        <HourglassEmpty style={{ fontSize: 50, color: "#ff9800" }} />
                        <Typography variant="h6">{surveysInProgress}</Typography>
                        <Typography>Surveys In Progress</Typography>
                    </CardContent>
                </Card>
                </Grid>
            </Grid>

            <Divider />
            <Typography>Completed Report</Typography>
            <Divider />
            {isLoading ? (
                <CircularProgress style={{ position: 'absolute', top: '20%', left: '50%' }} />
            ) : (
                <CompletedSurveysTable
                                    data={patientCollections ? patientCollections : []}
                                    role={appState.role}
                                />
            )}
        </div>
    );
};

export default MainReports;
